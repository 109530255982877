import { makeStyles, createStyles } from '@material-ui/core/styles';
import { SECONDARY_BACKGROUND } from '../../../theme/constants';

export const useSectionStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: ({ headingVariant }) => {
        if (headingVariant === 'h3') {
          return theme.spacing(3);
        }

        if (headingVariant === 'h4') {
          return theme.spacing(2);
        }

        if (headingVariant === 'h5' || headingVariant === 'h6') {
          return theme.spacing(1);
        }

        return theme.spacing(4);
      },
      background: ({ isSecondary }) => (isSecondary ? SECONDARY_BACKGROUND : 'transparent'),
      '&:first-child': {
        marginTop: 0,
      },
    },
    heading: {
      marginBottom: theme.spacing(1.5),
    },
    paragraph: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      '&:first-child': {
        marginTop: 0,
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
  }),
);
