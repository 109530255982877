import { graphql } from 'gatsby';
import * as React from 'react';

import { Link, Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { getImage } from 'gatsby-plugin-image';
import { Typography, Button } from '@material-ui/core';
import Seo from '../components/seo';
import { LandingPageLayout } from '../components/layout';
import { Section } from '../components/layout/Section';
import { EmbeddedAppPage } from '../components/EmbeddedAppPage';

const PageVisitorInstruction = ({ pageContext, data }) => {
  const { bannerImageCall } = data;
  const { t } = useTranslation(['visitor-instruction']);
  const bannerImage = getImage(bannerImageCall);

  const { language } = useI18next();

  const [isInstructionOpen, setIsInstructionOpen] = React.useState(false);
  return (
    <>
      <Seo title={t('meta.title')} description={t('meta.description')} lang={pageContext.language} />

      <LandingPageLayout
        bannerImage={bannerImage}
        bannerTitle={t('banner.title')}
        bannerDescription={t('banner.description')}
      >
        <Section heading={t('heading.gesamtverantwortung')}>
          <Typography>{t('content.gesamtverantwortung')}</Typography>
          <Typography>{t('content.gesamtverantwortung2')}</Typography>
          <Typography>
            <Trans
              t={t}
              components={[
                <Link key="0" to="/personnel-hygiene" />,
                <Link key="1" to="/forklift-drivers-instruction" />,
              ]}
              i18nKey="content.gesamtverantwortung3"
            />
          </Typography>
        </Section>

        <Section heading={t('heading.umoPerfekt')}>
          <Typography>{t('content.umoPerfekt')}</Typography>
          <Typography>{t('content.umoPerfekt2')}</Typography>
          <Typography>
            <Trans
              t={t}
              components={[<Link key="0" to="/instruction-archive" />, <Link key="1" to="/proof-of-instruction" />]}
              i18nKey="content.umoPerfekt3"
            />
          </Typography>
          <Typography>
            <Trans t={t} components={[<Link key="0" to="/product" />]} i18nKey="content.umoPerfekt4" />
          </Typography>
        </Section>
        <Section heading={t('heading.example')}>
          <Typography>{t('content.example')}</Typography>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              setIsInstructionOpen(true);
            }}
          >
            {t('button.example')}
          </Button>
          <EmbeddedAppPage
            open={isInstructionOpen}
            onClose={() => {
              setIsInstructionOpen(false);
            }}
            mode="full-screen"
            id="62eb85c97c1b800012395668"
            token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdhbmlzYXRpb25JZCI6IjYyMjY1YWVkMjdiYWM1MDAxMzQ4NDhmNCIsInR5cGUiOiJvcGVuLWluc3RydWN0aW9uIiwiaWF0IjoxNjg1NzA2NTMwLCJleHAiOjE4NDM0OTQ1MzB9.1EPZbK1Uen-9Zl6WJ6l69vpFZsCMN6YbJngCwk9fZ1I"
            title="Eingebettete Bürounterweisung von UMO"
            lng={language}
            queryParams={{
              firstName: t('guest'),
              lastName: t('guest'),
              identification: t('identification'),
            }}
          />
        </Section>
      </LandingPageLayout>
    </>
  );
};
export default PageVisitorInstruction;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    bannerImageCall: file(relativePath: { eq: "visitor.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
      }
    }
  }
`;
