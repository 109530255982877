import { Typography } from '@material-ui/core';
import React from 'react';
import { useSectionStyles } from './styles';
import { toDashCase } from './utils';

export const Section = (props) => {
  const { children, heading, headingVariant = 'h2', isSecondary } = props;

  const classes = useSectionStyles({ isSecondary, headingVariant });
  const id = toDashCase(heading);

  return (
    <section className={classes.root}>
      <Typography className={classes.heading} id={id} variant={headingVariant}>
        {heading}
      </Typography>
      {/* Füge der component paragraph styles hinzu, wenn sie vom typen Typograhpy mit der variante body1 oder body2 ist */}
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          const isMuiTypography = child.type && child.type.options && child.type.options.name === 'MuiTypography';
          const isParagraph =
            child.props && (!child.props.variant || child.props.variant === 'body1' || child.props.variant === 'body2');

          if (isMuiTypography && isParagraph) {
            return React.cloneElement(child, { className: classes.paragraph });
          }

          return child;
        }
        return null;
      })}
    </section>
  );
};
