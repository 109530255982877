/**
 * Transformiert leerzeichen vom eingabetext zu bindestrichen, und alles in klein buchstaben
 * @param {*} text string
 * @returns string
 */
export const toDashCase = (text) => {
  if (typeof text === 'string') {
    return text
      .split(' ') // split string into array for each space
      .join('-') // put the array back together as a string with a - as a separator
      .toLowerCase() // all lowercase letters
      .replace(/[^a-z0-9-]/gi, ''); // replace invalid characters like a ?
  }

  return null;
};
